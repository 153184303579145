<template>
  <div>
    <vue-tel-input
      v-model="numero"
      :defaultCountry="tel ? tel.phone_iso_code : null"
      v-bind="vueTel"
      @input="check"
      required
    >
    </vue-tel-input>
  </div>
</template>

<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  data() {
    return {
      numero: null,
      vueTel: {
        preferredCountries: ["FR", "TN"],
        placeholder: "Enter your phone",
        mode: "auto",
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        validCharactersOnly: true,
        wrapperClasses: "country-phone-input",
      },
    };
  },
  props: {
    tel: Object,
  },
  methods: {
    check(phone, event) {
      this.$emit("phoneNumber", event);
    },
  },
  mounted() {
    if (this.tel) this.numero = this.tel.phone_number;
  },
};
</script>
